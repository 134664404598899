/* Bootstrap overrides
-------------------------------------------------- */
$body: #000;
$headings-font-weight: 300 !important;
$primary: #1b46c2;
$light: #fafafa;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import url(../vendor/css/all.min.css);
@import url('https://fonts.googleapis.com/css?family=Rubik%3A400%2C400i%2C500%2C700%2C900%26display%3Dswap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');

/* FONTS */
@font-face {
    font-family: 'Nasalization';
    src: url('../fonts/nasalization/nasalization-rg.woff?#iefix') format('woff'),
        url('../fonts/nasalization/nasalization-rg.ttf?#iefix') format('truetype'),
        url('../fonts/nasalization/nasalization-rg.otf?#iefix') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Absolute';
    src:
        url('../fonts/absolute/absolute.ttf?#iefix') format('truetype'),
        url('../fonts/absolute/absolute.otf?#iefix') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/* VARIABLES */
$primaryColor: rgb(92, 168, 123);
$primaryColor: #B54223;
$lightColor: #cacaca;
$darkColor: #242526;
$fadedGreen: #273f32;

/* Base
-------------------------------------------------- */
html {
    min-height: 100%;
    font-size: 14px;
}

@media screen and (max-width:767px) {
    html {
        font-size: 13px;
    }
}

.wrapper {
    min-height: 100vh;
}

.opacity-6 {
    opacity: 0.6;
}

.dapp-icon {
    padding: 5px;
    border-radius: 50%;
    background-color: $light;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.icon-medium {
        width: 80px;
        height: 80px;
    }
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
    width: 85px;
    height: 25px;
}

.dapp-name {
    line-height: 1;
    border-left: 1px solid $gray-400;
    padding-left: 3px;
    margin-left: 4px;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    margin: 0 7px;

    button {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $white;

        &:hover {
            opacity: 0.9;
        }
    }
}

.transactions {
    .transaction-icon {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }

    &.table {

        .transaction-hash .trim {
            @include media-breakpoint-down(xl) {
                max-width: none;
            }

            @include media-breakpoint-down(lg) {
                max-width: 26rem;
            }

            @include media-breakpoint-down(md) {
                max-width: 12rem;
            }

            @include media-breakpoint-down(sm) {
                max-width: 8rem;
            }
        }

        td,
        tr {
            padding: 0.65rem;
        }

        td {
            white-space: nowrap;
            vertical-align: middle;
        }

        tr:last-of-type {
            td {
                border-bottom: 1px solid #dee2e6;
            }
        }
    }
}

/* Actions
-------------------------------------------------- */

.not-allwed {
    cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 27px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .bg-blue .lds-ellipsis div {
        background: rgba(255, 255, 255, 0.4);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 26px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 45px;
        animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }

    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }

        100% {
            transform: translate(19px, 0);
        }
    }
}




/*--------------------*/
/* GENERAL */
/*--------------------*/
/*
html, body{
    overflow-x: hidden;
    overflow-y: scroll;
}
*/
body,
html {
    font-weight: 300 !important;
    font-family: "Roboto", "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow-x: hidden;
    background: #000000;
}

img:not(.icon) {
    display: block;
    width: 100%;
}

@media screen and (max-width: 767px) {
    img:not(.icon) {
        display: block;
        width: 80%;
        margin-left: 10%;
    }
}

.bg-white {
    background-color: #fff !important;
}

.bg-green {
    background-color: #399143 !important;
}

.bg-white-muted {
    background-color: rgba(255, 255, 255, .05) !important;
}

.bg-black {
    background-color: #000 !important;
    background-color: #080808 !important;
    color: #fff;
}

.bg-dark {
    background-color: #000 !important;
    background-color: #1a1a1a !important;
    background-color: #111111 !important;
    color: #fff;
}

.bg-primary {
    background-color: $primaryColor !important;
}

.bg-databolt-yellow {
    background-color: #f8a432 !important;
}

.color-white {
    color: #fff !important;
}

.color-white-muted {
    color: rgba(255, 255, 255, .05) !important;
}

.color-gray {
    color: #555A64 !important;
}

.color-gray-light {
    color: #b1b5d3 !important;
}

.color-black {
    color: #000 !important;
}

.color-green {
    color: #399143 !important;
}

.color-primary {
    color: $primaryColor !important;
}

.badge-yellow {
    color: #fff;
    background-color: #f8a432;
}

.border-top-gray-light {
    border-top: 1px solid #b1b5d3;
}

.border-left-gray-light {
    border-left: 1px solid #b1b5d3;
}

.border-gray {
    border: 1px solid #b1b5d3;
}

.border-gray-light {
    border: 1px solid #b1b5d3;
}

.border-white {
    border: 1px solid #fff;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.heading0 {
    font-size: 7rem;
    line-height: 1;
    font-family: "Nasalization";
    font-weight: 500 !important;
}

.heading1 {
    font-size: 5rem;
    line-height: 1;
    font-weight: 500 !important;
}

.heading2 {
    font-size: 4rem;
    line-height: 1.125;
    font-weight: 500 !important;
}

.heading3 {
    font-size: 3rem;
    line-height: 1.125;
    font-weight: 500 !important;
}

.heading4 {
    font-size: 2rem;
    line-height: 1.125;
    font-weight: 500 !important;
}

.heading5 {
    font-size: 1.5rem;
    line-height: 1.125;
    font-weight: 500 !important;
}

.heading6 {
    font-size: 1.35rem;
    line-height: 1.125;
    font-weight: 500 !important;
}

.text {
    font-size: 1.25rem;
}

.text-small,
.blockquote-footer {
    font-size: .95rem;
    line-height: 1.35rem;
}

.text-300 {
    font-weight: 300;
}

.text-400 {
    font-weight: 400;
}

.text-500 {
    font-weight: 500;
}

.text-700 {
    font-weight: 700;
}

.font-weight-bold{
    font-weight: bold;
}

@media screen and (max-width: 767px) {
    .heading0 {
        font-size: 5rem;
        line-height: 1;
        font-family: "Nasalization";
    }

    .heading1 {
        font-size: 4rem;
        line-height: 1;
    }

    .heading2 {
        font-size: 3rem;
        line-height: 1.125;
    }

    .heading3 {
        font-size: 2rem;
        line-height: 1.125;
    }

    .heading4 {
        font-size: 1.5rem;
        line-height: 1.125;
    }

    .heading5 {
        font-size: 1.25rem;
        line-height: 1.125;
    }

    .heading6 {
        font-size: 1rem;
        line-height: 1.125;
    }
}

.text {
    color: #fff;
}

.font-400 {
    font-weight: 400;
}

.font-primary {
    //font-family: "Nasalization", "Ubuntu","Helvetica Neue", Helvetica, Arial, sans-serif; 
    font-family: "Absolute", "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.font-secondary {
    font-family: "Roboto", "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a {
    text-decoration: none !important;
}

main:not(.homepage) {
    margin-top: 80px;
}

section,
footer,
main:not(.homepage) {
    padding: 6rem 0;
    position: relative;
}

@media screen and (max-width: 767px) {

    section,
    footer,
    main:not(.homepage) {
        padding: 3rem 0;
    }
}

article {
    padding: 7.5rem 0 0 0;
}

article:last-of-type {
    padding-bottom: 7.5rem;
}

mark {
    color: inherit;
    padding: 0;
    background: 0 0;
    background-image: linear-gradient(120deg, $primaryColor 0%, $primaryColor 100%);
    background-repeat: no-repeat;
    background-size: 0% .5em;
    background-position: 0 80%;
    position: relative;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    background-size: 100% .5em;
}

.ul {
    list-style: none;
    padding-left: 1.25rem;
}

.ul.ul-check li::before {
    content: "";
    display: inline-block;
    width: .5rem;
    height: 1rem;
    border-color: $primaryColor;
    border-style: solid;
    border-width: 0 2px 2px 0;
    margin: .1rem 1.5rem .1rem .5rem;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: static;
    border-radius: 0;
    background: none;
}

@media screen and (max-width: 992px) {
    .heading1 {
        font-size: calc(1.575rem + 3.9vw);
    }

    .heading2 {
        font-size: 2.5rem;
    }

    .heading3 {
        font-size: calc(1.375rem + 1.5vw);
    }

    .text {
        font-size: 1.25rem;
    }

    section,
    article,
    footer {
        padding: 3.5rem 0;
    }

}

@media only screen and (min-width: 992px) {
    article:nth-of-type(odd) .article-image {
        webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    article:nth-of-type(odd) .article-data.offset-md-1 {
        margin-left: 0 !important;
        margin-right: 8.333333% !important;
    }
}

p {
    margin: 1.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

a,
.link-style {
    color: $primaryColor  !important;
}

/*--------------------*/
/* LOADING SECTION */
/*--------------------*/
#loading-mask {
    background: $primaryColor;
    height: 100%;
    left: 0;
    opacity: 1;
    filter: alpha(opacity=100);
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 9999999999;
    color: #fff;
}

#loading-mask .loading-img {
    height: 100%;
    left: 0;
    position: fixed;
    top: 50%;
    margin-top: -50px;
    width: 100%;
}

.scrollToTop {
    background-color: $primaryColor;
    position: fixed;
    left: 3%;
    bottom: 5%;
    font-size: 30px;
    z-index: 999999;
    display: block;
    width: 45px;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    display: none;
}



.scrollToTop:hover {
    color: #fff !important;
}


/*--------------------*/
/* HEADER SECTION */
/*--------------------*/
.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    z-index: 9999999;
    transition: ease .5s;
    background-color: #000;
    background: #080808;
}

header #logo {
    height: 60px;
    margin-top: 10px;
    width: auto;
    transition: ease .4s;
}


@media screen and (max-width: 992px) {

    header #logo {
        height: 50px;
        width: auto;
        margin-top: 10px;
        margin-left: 25px;

    }
}

/*--------------------*/
/* MENU SECTION */
/*--------------------*/
#toggle,
.button-toggle {
    display: none;
}

.navbar {
    -webkit-transition: 0.6s;
    transition: 0.6s;
    padding: 10px 1rem;
    line-height: 60px;
}

.navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;

}

.navbar-nav li a {
    padding: 0 15px;
    color: #fff !important;
    font-size: 15px;
}

.menu {
    display: contents;
    margin-right: auto;
}

.menu .right {
    display: flex;
    margin-left: auto;
}

.navbar .menu a:not(.btn) {
    position: relative;
    vertical-align: middle;
    line-height: 1;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.92857143em 1.14285714em;
    text-transform: none;
    color: rgba(0, 0, 0, 0.87);
    font-weight: normal;
    -webkit-transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
    transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;

    -ms-flex-item-align: end;
    align-self: flex-end;
    margin: 0em 0em -2px;
    padding: 0.85714286em 1.14285714em;
    -webkit-transition: color 0.1s ease;
    transition: color 0.1s ease;
    color: #fff !important;
}



@media screen and (max-width: 767px) {}

.sub-menu {
    display: none;
}

@media screen and (max-width: 767px) {
    .menu {
        margin-top: -100% !important;
    }

    .menu,
    .menu .right {
        display: block;
        margin: 0;
        width: 100%;
    }

    .navbar .menu a:not(.btn) {
        display: block;
        width: 90%;
        margin-left: 5%;
        text-align: center;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .menu .btn {
        width: 90%;
        margin-left: 5% !important;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    .sub-menu {
        display: none;
        margin-left: 0;
    }

}

.row {
    margin-left: -5px;
}

/***********************/
/* MENU for mobile */
/***********************/
.toggle-container {
    display: none;
}

@media screen and (max-width: 767px) {
    .toggle-container {
        display: block;
    }

    .navbar,
    .fixed .navbar {
        margin-top: 0;
    }

    .navbar-nav {
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
    }

    .navbar-nav li a:not(.btn) {
        color: #fff !important;
        font-size: 24px;
    }

    nav .container-fluid {
        margin-left: 0;
        justify-content: flex-end;
    }

    .navbar-nav>li {
        display: block;
    }

    .button-toggle {
        position: absolute;
        display: inline-block;
        z-index: 999999;
        width: 20px;
        height: 20px;
        top: 20px;
        border: none;
        cursor: pointer;
        right: 25px;
        padding: 15px;
        border-radius: 0;
        -webkit-transition: 0.6s;
        transition: 0.6s;
    }

    .button-toggle:hover {
        background-color: transparent !important;
    }

    .button-toggle .line {
        position: absolute;
        content: '';
        top: 0%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        border-radius: 5px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .button-toggle .line:nth-of-type(1) {
        top: 25%;
        -webkit-transform: translateY(0%) rotate(0deg) scale(1);
        -ms-transform: translateY(0%) rotate(0deg) scale(1);
        transform: translateY(0%) rotate(0deg) scale(1);
    }

    .button-toggle .line:nth-of-type(2) {
        top: 50%;
        -webkit-transform: translateY(50%) rotate(0deg) scale(1);
        -ms-transform: translateY(50%) rotate(0deg) scale(1);
        transform: translateY(50%) rotate(0deg) scale(1);
    }

    .button-toggle .line:nth-of-type(3) {
        top: 75%;
        -webkit-transform: translateY(100%) rotate(0deg) scale(1);
        -ms-transform: translateY(100%) rotate(0deg) scale(1);
        transform: translateY(100%) rotate(0deg) scale(1);
    }

    label {
        margin: 0 !important;
    }

    .nav-wrapper {
        position: fixed;
        height: 100%;
        width: 80%;
        z-index: 999999;
        left: 0;
        top: 0;
        background-color: #000;
        background-color: #080808;
        padding: 0;
        opacity: 1;
        -webkit-transition: all .4s ease-in 0s;
        -moz-transition: all .4s ease-in 0s;
        -o-transition: all .4s ease-in 0s;
        transition: all .4s ease-in 0s;
        -webkit-transform: translateY(0) translateX(-100%) scale(1);
        -ms-transform: translateY(0) translateX(-100%) scale(1);
        transform: translateY(0) translateX(-100%) scale(1);
        
    }

    .nav-wrapper .container-fluid{
        height: 90%;
        margin-top:5%;
        overflow: auto;
    }
    
    .nav-wrapper:after {}

    #toggle:checked~.dimmer {
        content: '';
        -webkit-transition: all .4s ease-in 0s;
        -moz-transition: all .4s ease-in 0s;
        -o-transition: all .4s ease-in 0s;
        transition: all .4s ease-in 0s;

        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.3);
    }

    #toggle:checked~.nav-wrapper {
        opacity: 1;
        -webkit-transform: translateY(0) translateX(0) scale(1);
        -ms-transform: translateY(0) translateX(0) scale(1);
        transform: translateY(0) translateX(0) scale(1);
    }


    #toggle:checked~.toggle-container .button-toggle .line:nth-of-type(1) {
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
        -ms-transform: translateY(-50%) rotate(45deg) scale(1);
        transform: translateY(-50%) rotate(45deg) scale(1);
    }

    #toggle:checked~.toggle-container .button-toggle .line:nth-of-type(2) {
        display: none;
    }

    #toggle:checked~.toggle-container .button-toggle .line:nth-of-type(3) {
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(-45deg) scale(1);
        -ms-transform: translateY(-50%) rotate(-45deg) scale(1);
        transform: translateY(-50%) rotate(-45deg) scale(1);
    }

}

.navbar-nav li a:hover,
.navbar-nav li a:active,
.navbar-nav li.current a,
sub-menu li a:hover {
    color: $primaryColor !important;
}

/***********************/
/* MENU for mobile */
/***********************/



/***********************/
/* HOME */
/***********************/
#canvas {

    width: 100%;
    min-height: 100hv;
    height: auto;
    margin: 0;
}

#canvas2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    z-index: 99999;
    color: #fff;
}

.slider-image {
    opacity: 0.7;
}

@media screen and (min-width: 992px) {
    section:not(.section-full-width):nth-of-type(even) .section-image {
        webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .product-item:nth-of-type(even) .product-image img {
        margin-left: 10%;
    }
}

@media screen and (max-width: 767px) {
    .slider-image {
        margin-left: -10% !important;
        width: 120% !important;
    }
}

/***********************/
/* FOOTER */
/***********************/
footer {
    background: #080808;
    padding-bottom: 0;
    padding-top: 0;
}

footer div {
    font-size: 1rem;
}

footer a {
    color: #9dabbd !important
}

footer a:hover {
    color: #fff !important
}

.footer-bottom {
    border-top: 1px solid #9dabbd;
}

.footer-navigation li {
    margin-top: 1rem;
}

footer .widget {
    padding-left: 4rem;
}

@media screen and (max-width: 767px) {
    footer .widget {
        padding-left: 2.2rem;
        margin-top: 1rem;
    }

    footer .social-icons {
        margin-top: 1.5rem !important;
    }
}

.social-icons .social-link:hover .social-link__icon path {
    fill: #fff;
}

.horizontal-line {
    height: 1px;
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 5%, rgba(177, 181, 211, 0.6) 50%, rgba(255, 255, 255, 0) 95%);
}

.highlighted-span2 {
    /*color: #FFB000;*/

    margin: 0 -0.4em;
    padding: 0.1em 0.2em;
    border-radius: 0.8em 0.3em;
    background: transparent;
    background-image: linear-gradient(to right,
            rgba(255, 176, 0, 0.1),
            rgba(255, 176, 0, 0.7) 4%,
            rgba(255, 176, 0, 0.3));
    -webkit-box-decoration-break: clon
}

.footer-partners a {
    display: inline-block;
}

.footer-partners img {
    height: 100px;
    width: auto;
    margin-left: auto;
}

.team a {
    display: inline-block;
}

.team img {
    height: 200px;
    width: auto;
    margin-left: auto;
}

/***********************/
/* TABS */
/***********************/

.nav-tabs {
    text-align: center;
    border: 1px solid #9dabbd;
    border-radius: 100px;
    display: inline-flex;

    border-color: $fadedGreen  !important;
    background-color: $darkColor;
}

.nav-tabs .nav-link {
    border-radius: 100px;
    margin: 2px;
    padding: 0.35rem 1rem;
    color: #9dabbd !important;
    font-size: 15px;
}


.nav-tabs .nav-link:hover {
    color: #fff !important;
    border: 1px solid transparent !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background: $primaryColor;
    color: #fff !important;
    border-color: $primaryColor;
}

.tab-content {
    width: 100%;
    margin-top: 1rem;
}

/***********************/
/* ACCORDION*/
/***********************/
.accordion {
    border-radius: 1rem !important;
    padding: 2px !important;

    //border: 1px solid $fadedGreen !important;
    background-color: #333;
    background-color: #000;
}

.accordion-item {
    border-radius: 1rem !important;
    margin: 30px 0 !important;
    color: #9dabbd !important;
    font-size: 15px;
    background-color: #1a1a1a;
    background-color: #111111;
    color: #fff;
    border: 1px solid $darkColor !important;
}

.accordion-button:focus,
.accordion-button {
    color: #fff !important;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 1.2rem 1.75rem;
    font-size: 1.25rem;
    cursor: pointer;
}

.accordion-body {
    border-top: 1px solid #333;
    color: #fff;
    background-color: $darkColor;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    font-size: 1.2rem;
    line-height: 1.95rem;
}

.accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    transform: rotate(-180deg);
}

.progress {
    display: flex;
    height: 2rem;
    overflow: hidden;
    font-size: 1rem;
    background-color: #ffffff;
    border-radius: 1rem;
    font-weight: bold;
    margin: 0.25rem;
}

.progress-bar {
    background-color: #2883d4;
}

.small-box {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 10px;
}

/***********************/
/* STAT BOXES */
/***********************/
.stats-box {
    border: 1px solid white;
    text-align: center;
    padding: 1.5rem .75rem;
    border-radius: 1rem !important;
    border: 1px solid #9dabbd;
    border: 1px solid $primaryColor;
    background-color: $darkColor;
}

.stats-box-small {
    padding: 1rem 0.5rem;
}

.stats-box img {
    width: 25%;
    height: auto;
    margin-left: 37.5%;
    opacity: 0.9;
}

/***********************/
/* BUTTONS */
/***********************/
.mybtn {
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    color: #f9f9f9 !important;
    line-height: 1;
    text-align: center;
    padding: 15px 30px !important;
    border: 0;
    border-radius: 1rem;
    outline: 0 none;
    cursor: pointer;
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .mybtn {
        display: block !important;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

.mybtn.btn-primary {
    border: 2px solid $primaryColor;
    background: $primaryColor;
    color: #fff !important;
}


.mybtn.btn-primary::hover {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #fff;
    color: #fff !important;
}
.btn-primary-disabled:hover{
    border: 2px solid $primaryColor;
    background: $primaryColor;
    color: #fff !important;
}


.mybtn.btn-bordered-white {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #fff;
}

.mybtn.btn-bordered-white:hover {
    background: transparent none repeat scroll 0 0;
    border: 2px solid $primaryColor;
    background: $primaryColor;
    color: #fff !important;
}

@media screen and ( max-width: 767px ){
    .mybtn.btn-bordered-white{
        width:100%!important;
    }
    .dapp-core-component__main__mx-3{
        margin: 0!important;
    }
}

.mybtn.btn-bordered-black {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #000;
    color: #000 !important;
}

.mybtn.btn-bordered-black:hover {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #000;
    background: #000;
    color: #fff !important;
}

.mybtn.btn-black {
    background: transparent none repeat scroll 0 0;
    border: 2px solid #000;
    background: #000;
    color: #fff !important;
}

.mybtn.btn-black:hover {
    background: transparent none repeat scroll 0 0;
    border: 2px solid $primaryColor;
    background: $primaryColor;
    color: #fff !important;
}

.extensionLoginButton div{
    width: auto;
    display: inline-block;
}

.extensionLoginButton svg{
    display: none!important;
}

.input-bordered-white {
    background: #2c2c2c none repeat scroll 0 0;
    border: 1px solid #9c9c9c;
    border-radius: 0.8rem;
    color: #cacaca;
}


/***********************/
/* MISC */
/***********************/
@media (min-width: 768px) {
    .col-md-25 {
        flex: 0 0 auto;
        width: 20%;
    }
}

.media-preloader {
    height: 225px;
}

.media-preloader.preloader-sm {
    height: 50px;
}

.loader {
    border: 7px solid #f3f3f3;
    /* Light grey */
    border-top: 7px solid $primaryColor;
    /* Blue */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 0.75s linear infinite;
    margin-left: calc(50% - 35px);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.preloader-sm .loader {
    border: 3px solid #f3f3f3;
    /* Light grey */
    border-top: 3px solid $primaryColor;
    /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 0.75s linear infinite;
    margin-left: calc(50% - 15px);
}

.item.unavailable {
    opacity: 0.7;
}

.items .card {
    padding: 1.5rem;
    border: none;
    border-radius: 8px;
    background-color: var(--card-bg-color) !important;
    box-shadow: 0 3px 20px 0px var(--primary-l-color);
    transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
    transition-duration: 0.3s;
    transition-property: opacity, transform;

    background-color: transparent !important;
    border: 1px solid rgba(255, 255, 255, 0.25) !important;
}

.items .card .card-body {
    padding: 0;
}

.items .card .card-body a {
    color: #fff !important;
}

.items .item {
    margin-top: 30px;
}

.image-over {
    overflow: hidden;
    border-radius: 2px;
}

.media-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px !important;
}

@media screen and (max-width: 767px) {
    .media-wrapper {
        width: 138px !important;
        height: 138px !important;
    }

    .media-wrapper img:not(.icon) {
        display: block;
        width: 100%;
        margin-left: 0%;
    }
}

.copy-box {
    margin-top: 10px;
    padding: 5px 15px;
    background: #cacaca;
    border-radius: 20px;
    display: inline;
    color: #111111;
    cursor: pointer;
    font-weight: 100;

    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.copy-box-dark {}

.copy-box:hover {
    background: #fff;
    color: #000;
}


.connect-page img {
    display: none;
}

.connect-page p {
    font-size: 16px;
}

.connect-page .btn.btn-primary {
    display: block;
    width: 100%;
    margin: 0 !important;
}

.accordion-item,
.accordion-button {
    //background: $lightColor;
    outline: none;
}

.slider-footer {
    margin-top: 30rem;
}

@media screen and (max-width: 767px) {
    .slider-footer {
        margin-top: 15rem;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.axonometric-right {
    border: 1px solid $primaryColor;
    border-radius: 1rem;
    transform: rotate(-20deg) skew(-20deg) scaleY(0.95) scaleX(0.9);
    box-shadow: inset 0em -.125em .25em rgba(0, 0, 0, .1);
}

.axonometric-left {
    border: 1px solid $primaryColor;
    border-radius: 1rem;
    transform: rotate(20deg) skew(20deg) scaleY(0.95) scaleX(0.9);
    box-shadow: inset -.125em 0em .25em rgba(0, 0, 0, .1);
}

.nftstostake {
    min-height: auto;
    max-height: 600px;
    overflow: hidden;
    overflow-y: auto;
}

.img-mirror {
    transform: rotateY(180deg);
}

.mybtn.disabled,
.mybtn.disabled:hover {
    background-color: #cacaca !important;
    color: #000 !important;
}

@media screen and (min-width: 767px) {
    .w-md-auto {
        width: auto;
    }

    .w-md-100 {
        width: 100%;
    }
}

.transactions-toast-list_toastContainer,
.progress {
    background: #000 !important;
}

.progress {
    border: 1px solid $primaryColor;
}

.canvasjs-chart-credit {
    display: none !important;
}

.apexcharts-legend-text {
    color: #fff !important;
}

.apexcharts-canvas {
    margin-left: 4%;
}

@media screen and (max-width: 767px) {
    .apexcharts-canvas {
        margin-left: -10%;
    }
}

.quantity-selector-top {
    height: 25px;
    line-height: 1;
}

.quantity-check {
    margin-top: -24px !important;
    margin-left: -21px !important;
}

// progress bar larger & blue
//change pill navigation with img

//CHANGES FOR CRYPTONUNS
.main-video {
    width: 100%;
    height: auto;
    opacity: 0.8;
}

@media screen and (min-width: 767px) {
    .main-header {
        margin-top: 7rem;
    }

    .position-md-absolute {
        position: absolute;
    }
}

.is-sticky {
    //position: fixed;
    //top:0;
    //left:0;
    z-index: 999;
    //width:636px;
    //height:auto;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;

    margin-top: 50%;
}

.timeline-bullet {
    position: absolute;
    left: 0;
    top: 20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    margin-left: -18px;
}

.audio-song {
    position: fixed;
    left: 50%;
    bottom: 10px;
    margin-left: -50px;
    width: 100px;
}

.light-box {
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px solid #666;
    background: #2a2a2a;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}

.ReactModal__Overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(33,33,33, 0.1) !important;
    z-index: 999;
}

// this is better to use transform for center the modal and width 
.ReactModal__Content {
    position: absolute; // if it's inside your ReactModal__Overlay if not use Fixed and a higher z-index.
    left: 50%;
    top: 50%;
    overflow: auto;
    background-color: black !important;
    -webkit-transform: translate(-50, -50%);
    transform: translate(-50, -50%);
    border: 1px solid rgb(33,33,33)!important;
}

@media screen and (min-width: 992px) {

    // desktop
    .modal.modal-content {
        max-width: 80% !important;
        max-height: 50% !important;
        padding-top: 100px !important;
    }
}

@media screen and (min-width: 426px) and (max-width: 991) {

    // tablet size
    .modal.modal-content {
        max-width: 80%;
        max-height: 80%;
    }
}

@media screen and (max-width: 425px) {

    // mobile size
    .modal.modal-content {
        max-width: 100%;
        max-height: 100%;
    }
}

.dropdown-menu{
    background: transparent;
    background: #2c2c2c;
    //display: block;
    position: absolute!important;
    width: auto;
    min-width: 230px;
    margin-left: -90px;
    padding: 15px 5px;
}

.dropdown-menu > li{
    line-height: 2.5!important;
}

.dropdown-menu a{
    display: block;
}

@media screen and( max-width: 767px ){
    .dropdown-menu{
        position: relative!important;
        width: 100%;
        margin-left: 0;
        background: transparent;
        margin-left: 0%;
        display: block!important;
        margin:0!important;
        padding: 5px;
    }
    
    .dropdown-menu a{
        font-size:18px!important;
    }
    
}

.dropdown:hover .dropdown-menu{
    display: block;
}

/*modal*/
.dapp-core-component__dappModalStyles__dappModalHeader{
    background-color: transparent!important;
}

.dapp-core-component__dappModalStyles__dappModalContent{
    background: #444!important;
    color: #fff!important;
    border: none!important;    
    border-radius: 5px!important;
}

.dapp-core-component__dappModalStyles__dappModalCloseButton:hover,
.dapp-core-component__dappModalStyles__dappModalCloseButton{
    background: transparent!important;
    color: #fff!important;
    padding: 0.75rem 1rem!important;
}

.dapp-core-component__transactionsToastListStyles__toasts.dapp-core-component__transactionsToastListStyles__toastWrapper {
    background-color: #2c2c2c!important;
    position: relative!important;
    color: #fff!important;   
}

.dapp-core-component__main__card{
    background:transparent!important;
}

.media-preloader{
    height: 280px;
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid $primaryColor; /* Blue */
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 0.75s linear infinite;
  margin-left: calc( 50% - 35px );
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

//
.ReactModal__Overlay{
    z-index:1000000000000000000!important;
}

.ReactModal__Content h3{
    text-align: center!important;
}
.ReactModal__Content{
    inset: 0!important;
    width: 70%!important;
    height: 85%!important;
    left: 15%!important;
    top: 7.5%!important;
    overflow:hidden!important;
}
@media screen and ( max-width: 767px ){
    .ReactModal__Content{
        inset: 0!important;
        width: 100%!important;
        height: 100%!important;
    }
}

.modal-body-scrollable{
    height: 625px!important;
}

@media screen and (max-width: 767px){
    .modal-body-scrollable{
        height: 470px!important;
    }
}

.dapp-core-component__transactionsToastListStyles__toasts{
    z-index: 99999999999999999999999999999999999!important;
}

.image-full-link{
    position: absolute;
    top:3px;
    right: 20px;
    color: #fff!important;    
    display: none;
}

.rarity-nft-item .image-full-link{
    top: 5px;
    right: 10px;
}

.rarity-nft-item:hover .image-full-link,
.nft-item:hover .image-full-link{
    display: block;
}
